import LocalStorage from '../../../Helpers/MFXLocalStorage';
import { Navigate, Outlet } from 'react-router-dom';

export function VerifyConnectionKey() {
  const urlKey = document.location.pathname.split('/')[1];

  if (
    LocalStorage.hasKeyInDB(urlKey) &&
    LocalStorage.getCurrentPath() !== urlKey
  ) {
    LocalStorage.setCurrent(urlKey, false, '', true);
  }

  return <Outlet />;
}
